<template>
    <div class="reset-password__view">
        <v-form
            class="reset-password__view__form"
            @submit.prevent="passwordReset"
        >
            <h1 class="headline mb-5">Password Reset</h1>

            <v-container>
                <v-row>
                    <v-text-field
                        v-model="form.email"
                        :rules="[rules.required, rules.email]"
                        solo
                        label="E-mail"
                    ></v-text-field>
                </v-row>
                <v-row>
                    <v-text-field
                        :type="'Password'"
                        :rules="[rules.required]"
                        label="Password"
                        v-model="form.password"
                        solo
                        @input="onPswChange"
                    ></v-text-field>
                </v-row>
                <v-row>
                    <v-text-field
                        :type="'Password'"
                        :rules="[rules.required]"
                        label="Confirm Password"
                        v-model="form.passwordConfirm"
                        solo
                        @input="onPswChange"
                    ></v-text-field>
                </v-row>
            </v-container>
            <v-row>
                <div v-if="!passwordsMatch" class="v-text-field__details">
                    <div class="v-messages theme--dark error--text" role="alert">
                        <div class="v-messages__wrapper">
                            <div class="v-messages__message text-center">Passwords must match.</div>
                        </div>
                    </div>
                </div>
            </v-row>
            <v-row class="justify-center align-center">
                <v-btn
                    @click="goToLogin"
                    outlined
                >Return</v-btn>
                <v-btn
                    type="submit"
                    class="ma-2"
                    :disabled="!passwordsMatch"
                    outlined
                >Reset Password</v-btn>
            </v-row>
        </v-form>
    </div>
</template>

<script>
export default {

    name: 'ForgotPassword',

    data: () => ({
        passwordsMatch: false,
        form: {
            email: '',
            password: '',
            passwordConfirm: ''
        },
        rules: {
            required: value => !!value || 'Required',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || 'Invalid e-mail';
            }
        }
    }),

    methods: {

        passwordReset() {
            this.form.token = this.$route.query.token;
            this.$store.dispatch('auth/passwordReset', this.form)
            .then(() => {
                this.$store.dispatch('reset').then(() => {
                    this.$router.push('/login');
                });
            });
        },

        onPswChange() {
            if (this.form.password === this.form.passwordConfirm) {
                this.passwordsMatch = true;
                return;
            }
            this.passwordsMatch = false;
        },

        goToLogin() {
            this.$router.push('/login');
        }
    },

    created() {
        if (typeof this.$route.query.token === 'undefined') {
            this.$router.push('/login');
        }
    }
}
</script>

<style lang="scss" scoped>
    .reset-password__view {
        position: absolute;
        top: -48px;
        left: 0;
        width: 100%;
        height: calc(100% + 48px);
        display: flex;
        align-items: center;
        justify-content: center;
        background: radial-gradient(circle, #564490 0%, #464A84 48%);

        &__form {
            margin: 0 auto;
            h3 {
                font-family: cursive;
                color: #f37ab7;
            }
        }
    }
</style>
